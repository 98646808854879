import React from 'react';
import history from '../../history';
import Search from './Search/Search';
import boatIcon from './images/boat.svg';
import worldIcon from './images/world.svg';
import wage from './images/wage.svg';
import './landingPage.css';

// import MessagingBanner from "../MessagingBanner/MessagingBanner";

class LandingPage extends React.Component {
  state = { screenSize: 0 };

  componentDidMount() {
    this.setState({ screenSize: window.innerWidth });

    window.scrollTo(0, 0);
  }

  screenSize = () => {
    if (this.state.screenSize < 654) {
      return 'large';
    } else {
      return 'massive';
    }
  };

  render() {
    return (
      <div>
        <div className="background-pic">
          <div className="get-your-boat-container">
            <div className="get-your-boat-container-text">
              <h1 className="get-your-boat-bold-text">
                Peer-to-Peer Boat Rentals!
              </h1>
              <br />
              <p className="get-your-boat-intro-text">
              Pier-to-pier, your friends with a boat!
              </p>

              {/* <p><MessagingBanner type="success" message="Welcome to BoatRBO! Explore, Signup, Rent, List, it's FREE!" /></p> */}

            </div>            
            <div style={{ marginBottom: '20px' }}>
              <button
                className="ui primary button explore-boats"
                onClick={() => history.push('/boatResult')}
              >
                Explore Boats
              </button>
            </div>
            <Search size={this.screenSize()} />
          </div>
        </div>
        <div className="generate-income">
          <div className="generate-income-boat">
            <img src={boatIcon} alt="Thousands of boats" />
            <p>Thousands of boats<br />
              for hire in one app!</p>
          </div>
          <div className="generate-income-boat">
            <img src={wage} alt="List your boat" />
            <p>List your boat<br />
            and generate income</p>
          </div>
          <div className="generate-income-boat">
            <img
              src={worldIcon}
              alt="We have boats for hire"
            />
            <p>We have boats for hire<br />
            all over the world</p>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
