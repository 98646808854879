import React from 'react';
import { Slide } from 'react-slideshow-image';

// import 'react-slideshow-image/dist/styles.css';

class ImageSlider extends React.Component {
  render() {
    // console.log('selectBoat.images:', this.props.selectBoat.images);
    // console.log('selectBoat:', this.props.selectBoat);
    const { selectBoat } = this.props;
    if (!selectBoat || !selectBoat.images) {
      // Handle the case where selectBoat or its images are not defined
      return null; // or show a placeholder image
    }

    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: false,
      arrows: true,
      pauseOnHover: true
    };
    return (
      <Slide {...properties}>
        {this.props.selectBoat.images &&
          this.props.selectBoat.images.map((photo, index) => (
            <div key={index}>
              <div 
                style={{width: '100%',height: '400px', backgroundSize: 'cover'
                }}
              >
                <div
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0.8) 70.42%, rgba(0, 0, 0, 0.8) 109.39%), url(${photo})`,
                    width: '100%',
                    height: '400px',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '5px 5px 0px 0px',
                    backgroundBlendMode: 'multiply, normal',
                  }}
                  className="boat-result-search-result-boat-list-card-pic"
                ></div>
              </div>
            </div>
        ))}
      </Slide>
    );
  }
}

export default ImageSlider;
