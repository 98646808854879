import React, { useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectBoat } from '../../../actions/index';

class BoatCard extends React.Component {
  render() {
    const { data } = this.props;

    const slideImages = !data.images ? '' : data.images;

    const handleCurrency = () => {
      if (data.currency === 'USD') {
        return '$';
      } else if (data.currency === 'EUR') {
        return '€';
      } else if (data.currency === 'GBP') {
        return '£';
      } else if (data.currency === 'NGN') {
        return '₦';
      }
    };

    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: false,
      arrows: false,
      pauseOnHover: true,
    };

    return (
      <div
        to="/selectboat"
        className="boat-result-search-result-boat-list-card"
        key={data._id}
        style={{ width: '100%', marginTop: '0', height: '100%' }}
      >
        <Slide {...properties}>
          {slideImages.map((image, index) => (
            <div style={{ width: '100%', height: '150px' }} key={index}>
              <div
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0.8) 70.42%, rgba(0, 0, 0, 0.8) 109.39%), url(${image})`,
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: '5px 5px 0px 0px',
                  backgroundBlendMode: 'multiply, normal',
                }}
                className="boat-result-search-result-boat-list-card-pic"
              ></div>
            </div>
          ))}
        </Slide>
        <div
          className="boat-result-search-result-boat-list-card-price"
          style={{ top: '110px', left: '30px', textAlign: 'center' }}
        >
          <p
            className="boat-result-search-result-boat-list-card-price-tag"
            style={{ fontSize: '28px' }}
          >{`$${data.amountPayed}`}</p>
        </div>
        <div
          className="boat-result-search-result-boat-list-card-details"
          style={{ paddingBottom: '12px' }}
        >
          <div style={{ display: 'flex' }}>
            <h2
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '16px',
                lineHeight: '30px',
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                marginLeft: '5px',
                color: ' #000000',
              }}
            >
              <p style={{ marginRight: '5px' }}>{data.boatManufacturer}</p>
              {data.boatModel}
            </h2>
          </div>
          <div style={{ marginLeft: '5px' }}>
            <p
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '18px',
                display: 'flex',
                alignItems: 'center',

                color: '#787878',
              }}
            >
              {data.date}
            </p>
          </div>
          <div style={{ marginLeft: '5px' }}>
            <p
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '18px',
                display: 'flex',
                alignItems: 'center',

                color: '#787878',
              }}
            >
              {data.city}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { selectBoat })(BoatCard);
