import React from 'react';
import boat4 from '../images/boat4.png';
import boat5 from '../images/boat5.png';
import boat6 from '../images/boat6.png';

const Addyourboat = ({ addYourBoatDisplay }) => {
  return (
    <div>
      {addYourBoatDisplay ? (
        <>
          <div className="help-content-grid">
            <img src={boat4} alt="boat4" className="help-content-boat1" />
            <div className="help-content-search-and-filter">
              <h1 className="help-content-search-and-filter-header">
                List for FREE!
              </h1>
              <p className="help-content-search-and-filter-content">
                List your boat for hire with us for FREE! We put you
                directly in touch with your passengers all within in our app.
                We make booking and processing easy. Our goal is to reduce your
                cost of bookings (COB) and increase your charters year over year.
              </p>
            </div>
          </div>
          <div className="help-content-grid">
            <div className="help-content-search-and-filter">
              <h1 className="help-content-search-and-filter-header">
                List your boat description
              </h1>
              <p className="help-content-search-and-filter-content">
                Our easy to use platform allows you to include detailed descriptions of your vessel
                including images, and pricing. You can make updates to your listing at any time
                right within your Account page. It's a perfect partnership, we handle increasing your sales
                and you get to spend more time on the water or sit back and collect more money!
              </p>
            </div>
            <img src={boat5} alt="boat5" className="help-content-boat1" />
          </div>
          <div className="help-content-grid">
            <img src={boat6} alt="boat6" className="help-content-boat1" />
            <div className="help-content-search-and-filter">
              <h1 className="help-content-search-and-filter-header">
                See your bookings
              </h1>
              <p className="help-content-search-and-filter-content">
                Keep track of your bookings right from your Dashboard.
                Set your own price and keep more of your earnings!
                We have the lowest booking fees in the industry.
                Listing your boat is comletely FREE!
                Our 8.25% service fee covers platform management,
                identity checks, 24/7 customer service, marketing services,
                fraud protection, secure payment transactions,
                continued service enhancements, including quick and easy
                online payments with built in fraud protection straight to your bank account.
              </p>
            </div>
          </div>{' '}

          <details>
            <summary>
            <h1 className="help-content-search-and-filter-header">
                        Let's review how payments and payouts work with BoatRBO!
                        </h1>
              <span class="icon">👇</span>
            </summary>
            <div className="help-content-grid">
              <div className="help-content-search-and-filter-content">
                <p className="help-content-search-and-filter-content">                
                  BoatRBO charges an 8.5% service fee for all bookings based on the rate you charge the customer. There is an additional 3% currency conversion transaction fee for all international bookings.
                  For example, if you charge the customer $200 for your booking, your service fee will be $17.00 (8.5% of $200) and your payout would be $183.00 ($200 - $17.00).
                  If securing an international booking, your service fee would be $23.00 (11.5% of $200) and your payout would be $177.00 ($200 - $23.00).
                  Our service fee is deducted from the balance upon your payout. We collect full payment from the customer upon booking. This guarantees your payout is secure and verifies the customer's funds are available for the entirety of the excursion.
                  Payout occurs after a trip is completed to assure the trip went smoothly and to ensure there are no refunds requested. Please confirm the trip was a success and we'll issue your payout right away. We issue payouts via bank transfer or via PayPal.
                  Depending on your bank, payouts can take several business days to appear in your account. BoatRBO does NOT charge the customer a security deposit upfront. To keep you protected in the event of damage or mishap, we offer a similar remedy called a security allowance. 
                  The security allowance may be used for minor damages, additional expenses, or charges that accrue during a rental such as gas or cleaning fees. You may set your security allowance in the details within your listing. We like ot operate openly and honestly, so please be
                  truthful and accurate with your clients and potential clients about what to expect from your vessel or service. If you make a valid claim for the security allowance post reservation, BoatRBO has the authorization to charge the customer's credit card for up to that amount
                  but only if a valid claim is filed and approved by BoatRBO management. This reduces additional charges at time of booking and also provides you security in case an issue occurs during the rental.
                  BoatRBO leaves you in charge of your own charter service. BoatRBO allows owners to set their own cancellation policies. Your policies MUST be set clearly and correctly in your BoatRBO listing in order for us to help enforce them.
                  Avoid canceled bookings by clearly stating your cancellations policy. We will issue full or partial refund payments based upon the policy set by you, as considered fair for both the boat owner and renter. 
                  Our focus is to ensure our boat owners are happy and they provide our customers with a great peer-to-peer rental experience. 
                </p>
              </div>
              {' '}
            </div>
          </details>

        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default Addyourboat;
