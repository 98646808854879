import React from 'react';
import Dashboard from './Dashboard';

class CaptainProfile extends React.Component {
  render() {
    return (
      <>
        <Dashboard />
      </>
    );
  }
}

export default CaptainProfile;
