import React from 'react';
import boat1 from '../images/boat1.png';
import boat2 from '../images/boat2.png';
import boat3 from '../images/boat3.png';

const Rentaboat = ({ rentABoatDisplay }) => {
  return (
    <>
      {rentABoatDisplay ? (
        <>
          <div className="help-content-grid">
            <img src={boat1} alt="boat1" className="help-content-boat1" />
            <div className="help-content-search-and-filter">
              <h1 className="help-content-search-and-filter-header">
                Search and filter
              </h1>
              <p className="help-content-search-and-filter-content">
                Set search filters to narrow your boat criteria or search
                our entire catalog of boats for hire around the world.
                Regardless of the type of boat or location, we have a vessel
                that will exceed all of your expectations!
              </p>
            </div>
          </div>
          <div className="help-content-grid">
            <div className="help-content-search-and-filter">
              <h1 className="help-content-search-and-filter-header">
                Select boat from list
              </h1>
              <p className="help-content-search-and-filter-content">
                Once you have decided upon the perfect boat for you,
                our easy booking process makes setting and confirming
                your rental boat a sea-breeze!
              </p>
            </div>
            <img src={boat2} alt="boat2" className="help-content-boat1" />
          </div>
          <div className="help-content-grid">
            <img src={boat3} alt="boat3" className="help-content-boat1" />
            <div className="help-content-search-and-filter">
              <h1 className="help-content-search-and-filter-header">
                Book or chat with the captain
              </h1>
              <p className="help-content-search-and-filter-content">
                Our booking process puts you directly in touch with your
                Captain. You can communicate with your Captain directly
                in our app. Another way we go above and beyond to make
                your booking and travel process as convenient as possible!
              </p>
            </div>
          </div>{' '}
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};
export default Rentaboat;
