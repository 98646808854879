import React from 'react';
import Footer from '../Footer/Footer';
import history from '../../history';
import ellipse1 from './Images/Ellipse1.png';

import './aboutUs.css';

class AboutUs extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="aboutUs-container">
        <div className="aboutUs-background-pic">
          <h1 className="aboutUs-background-header">About Us</h1>
        </div>
        <h1 className="aboutUs-background-welcome">Welcome to BoatRBO, Peer-To-Peer Boating Club!</h1>
        <div
          style={{
            width: '80%',
            textAlign: 'center',
            display: 'inline-block',
            marginTop: '20px',
            marginBottom: '40px',
          }}
        >
          <p className="aboutUs-white-text">
            We have boats for hire all over the world, lake to lake, and sea to shining sea!
            List your boat on our world wide platform and access customers looking to book today.
            We provide state of the art booking systems to assist you in securing more bookings,
            staying in touch with your clients, and secure payment processing.
          </p>
        </div>
        <div className="aboutUs-grey-background">
          <h1 className="aboutUs-grey-text-header">Our Mission</h1>
          <div
            style={{
              width: '80%',
              textAlign: 'center',
              display: 'inline-block',
              marginTop: '30px',
              marginBottom: '40px',
            }}
          >
            <p className="aboutUs-white-text">
            To first and foremost, encourage and support boating interests of our members and guests.
            We strive to provide you with the ultimate boating charter and booking experience by consistently
            providing a platform that exceeds our customer's expectations.            
            </p>
          </div>
        </div>
        <div className="aboutUs-join-us">
          <div className="aboutUs-join-us-align">
            <p className="aboutUs-join-us-text">
              Join BoatRBO's massive network of happy boaters today!
            </p>
            <button
              className="aboutUs-join-us-button"
              onClick={() => history.push('/signUp')}
            >
              <b>Join BoatRBO!</b>
            </button>
          </div>
        </div>
        <div className="aboutUs-customers-reviews">
          <h1 className="aboutUs-customers-reviews-header">
            What our boaters say about our service
          </h1>
          <div className="aboutUs-customers-reviews-content">
            <div className="aboutUs-customers-reviews-content-card">
              <img
                src={ellipse1}
                alt="Boater"
                className="aboutUs-customers-reviews-content-card-picture"
              />
              <p className="aboutUs-customers-reviews-content-card-name">
                Pedro De Pacas
              </p>
              <div className="aboutUs-customers-reviews-content-card-review-container">
                <p className="aboutUs-customers-reviews-content-card-review">
                  BoatRBO Boating Club was a pleasure to do business with. They made
                  my charter experience really simple. Thanks!
                </p>
              </div>
              <p className="aboutUs-customers-reviews-content-card-location">
                Miami, FL
              </p>
            </div>
            <div
              className="aboutUs-customers-reviews-content-card"
              style={{ background: '#F6F6F6' }}
            >
              <img
                src={ellipse1}
                alt="Boater"
                className="aboutUs-customers-reviews-content-card-picture"
              />
              <p className="aboutUs-customers-reviews-content-card-name">
                Aiden Gill
              </p>
              <div className="aboutUs-customers-reviews-content-card-review-container">
                <p className="aboutUs-customers-reviews-content-card-review">
                  We had such a great experience, we just booked another charter with BoatRBO
                  and looking forward to another awesome experience!
                </p>
              </div>
              <p className="aboutUs-customers-reviews-content-card-location">
                Noord, Aruba
              </p>
            </div>
            <div className="aboutUs-customers-reviews-content-card">
              <img
                src={ellipse1}
                alt="Boater"
                className="aboutUs-customers-reviews-content-card-picture"
              />
              <p className="aboutUs-customers-reviews-content-card-name">
                Dudely Studder-Muffin
              </p>
              <div className="aboutUs-customers-reviews-content-card-review-container">
                <p className="aboutUs-customers-reviews-content-card-review">
                  Had a blast aboard Dogg Daze, we will be back!
                </p>
              </div>
              <p className="aboutUs-customers-reviews-content-card-location">
                San Francisco, CA
              </p>
            </div>
            <div
              className="aboutUs-customers-reviews-content-card"
              style={{ background: '#F6F6F6' }}
            >
              <img
                src={ellipse1}
                alt="Boater"
                className="aboutUs-customers-reviews-content-card-picture"
              />
              <p className="aboutUs-customers-reviews-content-card-name">
                Buddy Buddy
              </p>
              <div className="aboutUs-customers-reviews-content-card-review-container">
                <p className="aboutUs-customers-reviews-content-card-review">
                  I highly recommend BoatRBO Boating Club for fishing charters in Destin, FL.
                  They had every boat option available and the booking process was a breeze.
                </p>
              </div>
              <p className="aboutUs-customers-reviews-content-card-location">
                Destin, FL
              </p>
            </div>
          </div>
        </div>
        <div className="aboutUs-line"></div>
        <div style={{ marginTop: '29px' }}>
          <p className="help-content-more-help">
            For more help?{' '}
            <span
              className="help-content-more-help-contact-us"
              onClick={() => history.push('/contact')}
            >
              Contact us
            </span>{' '}
            today
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AboutUs;
